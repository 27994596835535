<template>
  <div class="view">
    <top-nav active="2" :istop="true"></top-nav>
    <div class="topnav_bg_90"></div>
    <div class="pricing">
      <div class="title">
        {{ $t("pricing.choose_the_plan") }}
      </div>
      <div class="tabStyle">
        <el-tabs type="border-card" class="pricingTabsstyle" v-model="value">
          <div class="yearLogo">({{$t('pricing.save_up_to')}})</div>
          <el-tab-pane :label="$t('pricing.monthly')" name="first">
            <div class="contents">
              <div class="contents_item" :class="{'contents_item_hot':d.ishot}" v-for="(d,i) in pricelist" :key="i">
                <div v-if="d.ishot" class="most_popular_badge"></div>
                <div class="character">
                  <div class="contents_item_name">{{d.title}}</div>

                  <div class="contents_item_object" :class="[isID?'IDcontents_item_object':'']">{{d.object}}</div>
                </div>

                <p class="contents_item_price"> {{d.monthPrice=='Talk to us'?'':$t("pricing.starts_at")}}<span class="item_price"> {{d.monthPrice}}&nbsp;</span>{{d.monthPrice=='Talk to us'?'':$t("pricing.mth")}}</p>
                <!-- <p class="contents_paid_annually">{{d.monthPrice=='Talk to us'?'':'paid annually'}}</p> -->
                <p class="contents_paid_annually_empty" v-if="d.monthPrice=='Talk to us'">1</p>
                <p class="seats_number">{{d.seats}}</p>
                <span class="book_a_demo" :class="[isID?'IDbook_a_demo':'']" @click="showPopupWidget(d)">{{ $t("pricing.book_a_demo") }}</span>
                <p class="plan_includes">{{d.plans}}</p>
                <div class="plan_details" v-for="(dd,j) in d.planlist" :key="j">
                  <!-- <span>
                    <i></i>
                  </span> -->
                  <p>{{dd.title}}</p>
                  <span v-if="dd.infoContent.length" class="ismore">
                    <img @mouseenter="handleEnterInfo(dd.id)" @mouseleave="handleLeaveInfo(dd.id)" src="@/assets/imgs/pc/pricing/info.png" alt="">
                    <div class="info_popover" :class="{'info_popover_to_left':i==3&&!to_right}" v-if="dd.infoContent.length&&infoList[dd.id]">
                      <div v-for="(ddd,k) in dd.infoContent" :key="k">{{ddd}}</div>
                    </div>
                  </span>
                </div>
                <!-- <p class="plan_includes">Additional Service</p>
                <p class="plan_includes">{{d.service}}</p>
                <p class="plan_include_service">{{d.service_start}}</p>
                <div class="plan_details" v-for="(ee,j) in d.servicelist" :key="j+123">
                  <span><i></i></span>
                  <p>{{ee}}</p>
                </div> -->
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('pricing.yearly')" name="second">
            <div class="contents">
              <div class="contents_item" :class="{'contents_item_hot':d.ishot}" v-for="(d,i) in pricelist" :key="i">
                <div v-if="d.ishot" class="most_popular_badge"></div>
                <div class="character">
                  <div class="contents_item_name">{{d.title}}</div>

                  <div class="contents_item_object" :class="[isID?'IDcontents_item_object':'']">{{d.object}}</div>
                </div>

                <p class="contents_item_price"> {{d.yearPrice=='Talk to us'?'':$t("pricing.starts_at")}}<span class="item_price"> {{d.yearPrice}}&nbsp;</span>{{d.yearPrice=='Talk to us'?'':$t("pricing.mth")}}</p>
                <!-- <p class="contents_paid_annually">{{d.yearPrice=='Talk to us'?'':'paid annually'}}</p> -->
                <p class="contents_paid_annually_empty" v-if="d.yearPrice=='Talk to us'">1</p>
                <p class="seats_number">{{d.seats}}</p>
                <span class="book_a_demo" :class="[isID?'IDbook_a_demo':'']" @click="showPopupWidget(d)">{{ $t("pricing.book_a_demo") }}</span>
                <p class="plan_includes">{{d.plans}}</p>
                <div class="plan_details" v-for="(dd,j) in d.planlist" :key="j">
                  <!-- <span>
                    <i></i>
                  </span> -->
                  <p>{{dd.title}}</p>
                  <span v-if="dd.infoContent.length" class="ismore">
                    <img @mouseenter="handleEnterInfo(dd.id)" @mouseleave="handleLeaveInfo(dd.id)" src="@/assets/imgs/pc/pricing/info.png" alt="">
                    <div class="info_popover" :class="{'info_popover_to_left':i==3&&!to_right}" v-if="dd.infoContent.length&&infoList[dd.id]">
                      <div v-for="(ddd,k) in dd.infoContent" :key="k">{{ddd}}</div>
                    </div>
                  </span>
                </div>
                <!-- <p class="plan_includes">Additional Service</p>
                <p class="plan_includes">{{d.service}}</p>
                <p class="plan_include_service">{{d.service_start}}</p>
                <div class="plan_details" v-for="(ee,j) in d.servicelist" :key="j+123">
                  <span><i></i></span>
                  <p>{{ee}}</p>
                </div> -->
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      
      <div class="title">
        {{ $t("pricing.feature_sets_comparison") }}
      </div>
      <div class="compare_contents">
        <table class="compare_table">
          <tr>
            <th scope="col" class="item_name no_border"></th>
            <th scope="col" class="item_content" style="border-top:none;">
              <div>
                <span class="item_content_text">{{ $t("pricing.solo") }}</span>
              </div>
              <div>
                <span class="item_content_text">{{ $t("pricing.team") }}</span>
              </div>
              <div>
                <span class="item_content_text">{{ $t("pricing.pro") }}</span>
              </div>
              <div>
                <span class="item_content_text">{{ $t("pricing.enterprise") }}</span>
              </div>
            </th>
          </tr>
          <tr :class="{'compare_subtile':d.is_item_title}" v-for="(d,i) in comparelist" :key="i">
            <th scope="col" class="item_name"><span>{{d.item_name}}</span></th>
            <th scope="col" class="item_content" :class="{'item_content_type1':d.item_content_type=='1','item_content_type2':d.item_content_type=='2','item_content_type3':d.item_content_type=='3'}">
              <div v-for="(dd,j) in d.item_content_list" :key="j">
                <span v-if="dd=='tick_img'"><img src="@/assets/imgs/pc/feature/feature_include_tick.png" alt=""></span>
                <span class="item_content_text" v-else>{{dd}}</span>
              </div>
            </th>
          </tr>
        </table>
      </div>
      <div class="back_to_top" :class="[isID?'IDback_to_top':'']" @click="handleToTop">{{ $t("pricing.back_to_top") }}</div>
      <div class="trusted">
        <trusted class="title-size"></trusted>
      </div>
      <!-- <div class="trusted_by">
        <div class="trusted_by_title">Trusted By</div>
        <div class="trusted_by_icon_content">
          <el-carousel  :interval="10000" height="226px" indicator-position="outside">
            <el-carousel-item v-for="(company_collect,j) in companylist" :key="j">
              <div class="carou_item_clients">
                <span class="carou_item_client_logo" v-for="(d,i) in company_collect" :key="i">
                  <img :src="d.company_logo" :class="{'height_rely_img':d.company_name=='Foye','width_rely_img':d.company_name!='Foye'}" alt="">
                </span>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div> -->

    </div>
    <foot-nav></foot-nav>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value:"second",
      isID:false,
      pricelist: [
        {
          title: this.$t("pricing.solo"),
          monthPrice: this.$t("pricing.month_solo_price"),
          yearPrice: this.$t("pricing.year_solo_price"),
          object: this.$t("pricing.for_agent"),
          seats: this.$t("pricing.up_to_3"),
          plans: this.$t("pricing.solo_plan_includes"),
          planlist: [
            {
              id: 'a1',
              title: this.$t("pricing.arcanite"),
              infoContent: []
            },
            {
              id: 'a2',
              title: this.$t("pricing.arcanite_mobile"),
              infoContent: []
            },
            {
              id: 'a3',
              title: this.$t("pricing.arcanite_connect"),
              infoContent: []
            }, {
              id: 'a4',
              title: this.$t("pricing.basic_integrations"),
              infoContent: []
            },
            {
              id: 'a5',
              title: this.$t("pricing.connect_to_marketplace_title"),
              infoContent: []
            },
            {
              id: 'a6',
              title: this.$t("pricing.up_to_3_front_end"),
              infoContent: []
            },
            {
              id: 'a7',
              title: this.$t("pricing.supports_up_to_30_projects"),
              infoContent: ["Project data support is an on-demand service, which will incur additional charges"]
            }
          ],
          link: "https://meetings.hubspot.com/william463?embed=true",
          IDlink:"https://calendly.com/erni-yap",
          service: "Project Data Support Service",
          service_start: "(Starts at $100/month)",
          servicelist: [
            "Apartment - once per week",
            "Townhouse Project - twice per week",
            "House and Land Project - daily",
            "Number of projects supported - 30 / 50 / 80 / 200",
            "Additional Projects ($10/project/month)"
          ],
          ishot: false
        },
        {
          title: this.$t("pricing.team"),
          monthPrice: this.$t("pricing.month_team_price"),
          yearPrice: this.$t("pricing.year_team_price"),
          object: this.$t("pricing.for_agent"),
          seats: this.$t("pricing.up_to_10"),
          plans: this.$t("pricing.everything_in_solo_plan_plus"),
          planlist: [
            {
              id: 'b1',
              title: this.$t("pricing.daily_reporting_feature"),
              infoContent: [
                "Based on a standard set of data metrics"
              ]
            },
            {
              id: 'b2',
              title: this.$t("pricing.data_gathering_via_short_survey_links"),
              infoContent: ["Up to 5 survey links"]
            },
            {
              id: 'b3',
              title: this.$t("pricing.project_sharing_and_affiliations"),
              infoContent: ["Requires digital handshake via Arcanite Connect"]
            },
            {
              id: 'b4',
              title: this.$t("pricing.data_support"),
              infoContent: ["Project data support is an on-demand service, which will incur additional charges"]
            },
            
            {
              id: 'b5',
              title: this.$t("pricing.starts_from_5_front_end_seats"),
              infoContent: []
            },
            // {
            //   id: 'b6',
            //   title: "Support up to 50 projects",
            //   infoContent: ["Project data support is an on-demand service, which will incur additional charges"]
            // }
          ],
          link: "https://meetings.hubspot.com/william463?embed=true",
          IDlink:"https://calendly.com/erni-yap",
          service: "Project Data Support Service",
          service_start: "(Starts at $100/month)",
          servicelist: [
            "Apartment - once per week",
            "Townhouse Project - twice per week",
            "House and Land Project - daily",
            "Number of projects supported - 30 / 50 / 80 / 200",
            "Additional Projects ($10/project/month)"
          ],
          ishot: false
        },
        {
          title: this.$t("pricing.pro"),
          monthPrice: this.$t("pricing.month_pro_price"),
          yearPrice: this.$t("pricing.year_pro_price"),
          object: this.$t("pricing.for_developer"),
          seats: this.$t("pricing.up_to_30"),
          plans: this.$t("pricing.everything_in_team_plus"),
          planlist: [
            {
              id: 'c1',
              title: this.$t("pricing.basic_integrations"),
              infoContent: []
            },
            {
              id: 'c2',
              title: this.$t("pricing.web_apis"),
              infoContent: []
            },
            {
              id: 'c3',
              title: this.$t("pricing.advanced_reporting"),
              infoContent: []
            },
            {
              id: 'c4',
              title: this.$t("pricing.starts_from_30_front_end_seats"),
              infoContent: []
            },
            // {
            //   id: 'c2',
            //   title: "Web integration",
            //   infoContent: ["Advanced web integration may incur additional charge"]
            // },
            // {
            //   id: 'c3',
            //   title: "Advanced reporting",
            //   infoContent: []
            // }, {
            //   id: 'c4',
            //   title: "Off-the-plan VR credits",
            //   infoContent: ["Based on service agreement"]
            // },
            // {
            //   id: 'c5',
            //   title: "Up to 30 front end seats",
            //   infoContent: []
            // },
            // {
            //   id: 'c6',
            //   title: "Support up to 80 projects",
            //   infoContent: ["Project data support is an on-demand service, which will incur additional charges"]
            // }
          ],
          link: "https://meetings.hubspot.com/william463?embed=true",
          IDlink:"https://calendly.com/erni-yap",
          service: "Project Data Support Service",
          service_start: "(Starts at $100/month)",
          servicelist: [
            "Apartment - once per week",
            "Townhouse Project - twice per week",
            "House and Land Project - daily",
            "Number of projects supported - 30 / 50 / 80 / 200",
            "Additional Projects ($10/project/month)"
          ],
          ishot: true
        },
        {
          title: this.$t("pricing.enterprise"),
          monthPrice: this.$t("pricing.month_enterprise_price"),
          yearPrice: this.$t("pricing.year_enterprise_price"),
          object: this.$t("pricing.for_developer"),
          seats: this.$t("pricing.starts_at_60"),
          plans: this.$t("pricing.everything_in_pro_plus"),
          planlist: [
            {
              id: 'd1',
              title: this.$t("pricing.customised_admin_dashboard"),
              infoContent: []
            },
            {
              id: 'd2',
              title: this.$t("pricing.customised_permissions"),
              infoContent: []
            },
            {
              id: 'd3',
              title: this.$t("pricing.customised_sales_workflows"),
              infoContent: []
            }, {
              id: 'd4',
              title: this.$t("pricing.multi_office_support_features"),
              infoContent: []
            },
            {
              id: 'd5',
              title: this.$t("pricing.master_data_tracking_and_reporting"),
              infoContent: ["Customisation in reporting may incur longer onboarding time"]
            },
            // {
            //   id: 'd6',
            //   title: "Equimate access",
            //   infoContent: ["Subject to accreditations"]
            // },
            {
              id: 'd6',
              title: this.$t("pricing.vr_for_off_the_plan_projects"),
              infoContent: []
            },
            // {
            //   id: 'd6',
            //   title: "Ongoing VR services",
            //   infoContent: ["Max VR credit limit based on service agreement"]
            // },
            {
              id: 'd7',
              title: this.$t("pricing.starts_from_200_front_end_users"),
              infoContent: []
            },
            // {
            //   id: 'd8',
            //   title: "Support up to 200 projects",
            //   infoContent: ["Project data support is an on-demand service, which will incur additional charges"]
            // }
          ],
          link: "https://meetings.hubspot.com/william463?embed=true",
          IDlink:"https://calendly.com/erni-yap",
          service: "Project Data Support Service",
          service_start: "(Starts at $100/month)",
          servicelist: [
            "Apartment - once per week",
            "Townhouse Project - twice per week",
            "House and Land Project - daily",
            "Number of projects supported - 30 / 50 / 80 / 200",
            "Additional Projects ($10/project/month)"
          ],
          ishot: false
        },
      ],
      infoList: {},
      to_right: false,
      comparelist: [
        {
          item_name: this.$t("pricing.arcanite_crm"),
          is_item_title: true,
          item_content_list: [],
          item_content_type: "1"
        },
        {
          item_name: this.$t("pricing.contacts_management"),
          is_item_title: false,
          item_content_list: [this.$t("pricing.unlimited")],
          item_content_type: "1"
        },
        {
          item_name: this.$t("pricing.monthly_email_cap"),
          is_item_title: false,
          item_content_list: ["3k", "10k", "50k", this.$t("pricing.unlimited")],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.monthly_sms"),
          is_item_title: false,
          item_content_list: ["", "", "", "tick_img"],
          item_content_type: "1"
        },
        {
          item_name: this.$t("pricing.smart_campiagn"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.social_media_sharing"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.client_interaction_log"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.default_email_templates"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.customised_email_templates"),
          is_item_title: false,
          item_content_list: ["tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.administrations"),
          is_item_title: true,
          item_content_list: [],
          item_content_type: "1"
        },
        {
          item_name: this.$t("pricing.default_user_roles"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        }
        ,
        {
          item_name: this.$t("pricing.customised_user_role_settings"),
          is_item_title: false,
          item_content_list: ["", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.sales_administration_approval_settings"),
          is_item_title: false,
          item_content_list: ["", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.customised_approval_settings"),
          is_item_title: false,
          item_content_list: ["", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.customised_permission_control"),
          is_item_title: false,
          item_content_list: ["","", "", "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.projects_property"),
          is_item_title: true,
          item_content_list: [],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.new_property_profile"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.new_project_profile"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.share_projects"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img"],
          item_content_type: "3"
        }
        ,
        {
          item_name: this.$t("pricing.connect_to_marketplace"),
          is_item_title: false,
          item_content_list: ["", "tick_img", "tick_img", "tick_img"],
          item_content_type: "3"
        }
        ,
        {
          item_name: this.$t("pricing.number_of_marketplace_projects_links"),
          is_item_title: false,
          item_content_list: ["", "5", "5", this.$t("pricing.unlimited")],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.customise_text_for_linked_projects"),
          is_item_title: false,
          item_content_list: ["", "", "", "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.customise_files_for_linked_projects"),
          is_item_title: false,
          item_content_list: ["", "", "", "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.sales_management"),
          is_item_title: true,
          item_content_list: [],
          item_content_type: "1"
        },
        {
          item_name: this.$t("pricing.sales_dashboards"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "1"
        },
        {
          item_name: this.$t("pricing.sales_tracking"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.sales_advice_automation"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.number_of_sales_stages"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "1"
        },
        {
          item_name: this.$t("pricing.default_sales_reports"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.channel_sales_management"),
          is_item_title: false,
          item_content_list: ["","", "tick_img", "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.customised_sales_reports"),
          is_item_title: false,
          item_content_list: ["tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.teams_channel_management"),
          is_item_title: true,
          item_content_list: [],
          item_content_type: "1"
        },
        {
          item_name: this.$t("pricing.max_number_of_teams"),
          is_item_title: false,
          item_content_list: ["1","10","20",this.$t("pricing.unlimited")],
          item_content_type: "1"
        },
        {
          item_name: this.$t("pricing.max_number_of_digital_handshakes"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.default_team_settings"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.team_activities_reporting"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.customise_team_settings"),
          is_item_title: false,
          item_content_list: ["tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.data_reporting"),
          is_item_title: true,
          item_content_list: [],
          item_content_type: "1"
        },
        {
          item_name: this.$t("pricing.default_dashboard"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: "Customised dashboard",
          is_item_title: false,
          item_content_list: ["tick_img"],
          item_content_type: "3"
        },
        {
          item_name: "Default reports",
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.daily_reporting"),
          is_item_title: false,
          item_content_list: ["tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.max_file_storage"),
          is_item_title: false,
          item_content_list: ["100G", "200G", "500G", "1T"],
          item_content_type: "2"
        },
        // {
        //   item_name: "Equimate",
        //   is_item_title: true,
        //   item_content_list: [],
        //   item_content_type: "1"
        // },
        // {
        //   item_name: "Short Survey Link",
        //   is_item_title: false,
        //   item_content_list: ["tick_img", "tick_img"],
        //   item_content_type: "3"
        // },
        // {
        //   item_name: "Long Survey Link",
        //   is_item_title: false,
        //   item_content_list: ["-  Mortgage broker only  -"],
        //   item_content_type: "1"
        // },
        // {
        //   item_name: "Project file sharing via Short Survey Link",
        //   is_item_title: false,
        //   item_content_list: ["tick_img", "tick_img"],
        //   item_content_type: "3"
        // },
        // {
        //   item_name: "Equimate Finance Accreditation",
        //   is_item_title: false,
        //   item_content_list: ["-  Contact us  -"],
        //   item_content_type: "1"
        // },
        {
          item_name: this.$t("pricing.website_Services"),
          is_item_title: true,
          item_content_list: [],
          item_content_type: "1"
        },
        {
          item_name: this.$t("pricing.property_flyer_creation"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.website_api"),
          is_item_title: false,
          item_content_list: [ "tick_img", "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.upload_your_own_design"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.vr_content_access"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.off_the_plan_vr_credits"),
          is_item_title: false,
          item_content_list: ["tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.standard_web_profile"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.team_website"),
          is_item_title: false,
          item_content_list: ["", "tick_img", "tick_img", "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.email_campaigns"),
          is_item_title: true,
          item_content_list: [],
          item_content_type: "1"
        },
        {
          item_name: this.$t("pricing.user_own_mailserver"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "1"
        },
        {
          item_name: this.$t("pricing.smart_campaign"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "1"
        },
        {
          item_name: this.$t("pricing.advanced_campaign_features"),
          is_item_title: false,
          item_content_list: ["", "", "tick_img", "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.integrations"),
          is_item_title: true,
          item_content_list: [],
          item_content_type: "1"
        },
        {
          item_name: this.$t("pricing.rea"),
          is_item_title: false,
          item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
          item_content_type: "2"
        },
        {
          item_name: this.$t("pricing.domain"),
          is_item_title: false,
          item_content_list: ["tick_img","tick_img", "tick_img", "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.salesforce"),
          is_item_title: false,
          item_content_list: [this.$t("pricing.basic_integrations"), "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.propertyBase"),
          is_item_title: false,
          item_content_list: [this.$t("pricing.basic_integrations"), "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.hubSpot"),
          is_item_title: false,
          item_content_list: ["","", this.$t("pricing.basic_integrations"), "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.zoho"),
          is_item_title: false,
          item_content_list: ["","", this.$t("pricing.basic_integrations"), "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.xero"),
          is_item_title: false,
          item_content_list: ["","", this.$t("pricing.basic_integrations"), "tick_img"],
          item_content_type: "3"
        },
        {
          item_name: this.$t("pricing.agentBox"),
          is_item_title: false,
          item_content_list: ["","", this.$t("pricing.basic_integrations"), "tick_img"],
          item_content_type: "3"
        },
        // {
        //   item_name: "Realestate.com.au - Basic Integrations",
        //   is_item_title: false,
        //   item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
        //   item_content_type: "2"
        // },
        // {
        //   item_name: "Domain.com.au - Basic Integrations",
        //   is_item_title: false,
        //   item_content_list: ["tick_img", "tick_img", "tick_img", "tick_img"],
        //   item_content_type: "2"
        // },
        // {
        //   item_name: "Idealayer",
        //   is_item_title: false,
        //   item_content_list: ["tick_img", "tick_img"],
        //   item_content_type: "3"
        // },
        // {
        //   item_name: "InSpace Stack",
        //   is_item_title: false,
        //   item_content_list: ["tick_img", "tick_img"],
        //   item_content_type: "3"
        // },
        // {
        //   item_name: "Salesforce CRM",
        //   is_item_title: false,
        //   item_content_list: ["tick_img", "tick_img"],
        //   item_content_type: "3"
        // },
        // {
        //   item_name: "PropertyBase",
        //   is_item_title: false,
        //   item_content_list: ["tick_img", "tick_img"],
        //   item_content_type: "3"
        // },
        // {
        //   item_name: "Hubspot CRM",
        //   is_item_title: false,
        //   item_content_list: ["tick_img", "tick_img"],
        //   item_content_type: "3"
        // },
        // {
        //   item_name: "Zoho CRM",
        //   is_item_title: false,
        //   item_content_list: ["tick_img", "tick_img"],
        //   item_content_type: "3"
        // },
        // {
        //   item_name: "Xero (coming soon)",
        //   is_item_title: false,
        //   item_content_list: ["tick_img", "tick_img"],
        //   item_content_type: "3"
        // },
        // {
        //   item_name: "Agentbox",
        //   is_item_title: false,
        //   item_content_list: ["tick_img", "tick_img"],
        //   item_content_type: "3"
        // },
        // {
        //   item_name: "Customised",
        //   is_item_title: false,
        //   item_content_list: ["tick_img"],
        //   item_content_type: "3"
        // }
      ],
            companylist:[
        [{
          company_name: "Mcgrath",
          company_logo: require('@/assets/imgs/pc/trustBy/Mcgrath.jpeg')
        }, {
          company_name: "Stone",
          company_logo: require('@/assets/imgs/pc/trustBy/stone.jpeg')
        }, {
          company_name: "Piety",
          company_logo: require('@/assets/imgs/pc/trustBy/Piety.jpeg')
        }, {
          company_name: "Coronation",
          company_logo: require('@/assets/imgs/pc/trustBy/cor.jpeg')
        }, {
          company_name: "SquareYards",
          company_logo: require('@/assets/imgs/pc/trustBy/squareyard.jpeg')
        },{
          company_name: "SH",
          company_logo: require('@/assets/imgs/pc/trustBy/SH.jpeg')
        }, {
          company_name: "QuarterAcre",
          company_logo: require('@/assets/imgs/pc/trustBy/QuarterAcre.jpeg')
        }, {
          company_name: "Edge",
          company_logo: require('@/assets/imgs/pc/trustBy/Edge.jpeg')
        }, {
          company_name: "LivePropertyMarketing",
          company_logo: require('@/assets/imgs/pc/trustBy/LivePropertyMarketing.jpeg')
        }, {
          company_name: "Aland",
          company_logo: require('@/assets/imgs/pc/trustBy/Aland.jpg')
        }],
        [
        {
          company_name: "Ecospective",
          company_logo: require('@/assets/imgs/pc/trustBy/Ecospective.jpeg')
        }, {
          company_name: "ERA",
          company_logo: require('@/assets/imgs/pc/trustBy/ERA.jpg')
          
        }, {
          company_name: "Be100",
          company_logo: require('@/assets/imgs/pc/trustBy/be100.jpeg')
          
        }, {
          company_name: "idream",
          company_logo: require('@/assets/imgs/pc/trustBy/idream.png')
        }, {
          company_name: "Oxbridge",
          company_logo: require('@/assets/imgs/pc/trustBy/oxb.jpeg')
        },{
          company_name: "FamilyFirst",
          company_logo: require('@/assets/imgs/pc/trustBy/FamilyFirst.jpg')
        }, {
          company_name: "Libra",
          company_logo: require('@/assets/imgs/pc/trustBy/Libra.jpg')
        }, {
          company_name: "Qfirst",
          company_logo: require('@/assets/imgs/pc/trustBy/Qfirst.jpeg')
        }, {
          company_name: "Surplus",
          company_logo: require('@/assets/imgs/pc/trustBy/Surplus.jpeg')
        }, {
          company_name: "Wealthbridge",
          company_logo: require('@/assets/imgs/pc/trustBy/Wealthbridge.jpeg')
        }
      ]],
    }
  },
  created() {
    if(localStorage.getItem('country')=="id"){
      this.isID=true;
    }else{
      this.isID=false;
    }
    this.pricelist.forEach(e => {
      e.planlist.forEach(ee => {
        this.infoList[ee.id] = false
      })
    })
    this.to_right = document.body.clientWidth > 1640 ? true : false


     // google 针对calendly的分析 
     
    // window.addEventListener("message", function($el) {
    //   if(isCalendlyEvent($el)) {
    //     /* Example to get the name of the event */
        
    //     /* Example to get the payload of the event */
    //   }
    // },true);

  },
  methods: {
    handleToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    handleEnterInfo(id) {
      this.infoList[id] = true
      this.infoList = Object.assign({}, this.infoList)
    },
    handleLeaveInfo(id) {
      this.infoList[id] = false
      this.infoList = Object.assign({}, this.infoList)
    },
    showPopupWidget(d) {
      if(this.isID){
        Calendly.showPopupWidget(d.IDlink);
      }else{
        Calendly.showPopupWidget(d.link);
      }
      return false;
    },

        // google 针对calendly的分析 
    // isCalendlyEvent(e) {
    //   return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
    // }
  },
  components: {
    "top-nav": resolve => require(["./TopNav.vue"], resolve),
    "foot-nav": resolve => require(["./FooterNav.vue"], resolve),
    "trusted":(resolve) => require(["./Trusted.vue"], resolve),
  },
}
</script>
<style lang="scss" scoped>
.view {
  background: #fff;
  font-family: Poppins;
}
.pricing {
  width: 1210px;
  margin-left: auto;
  margin-right: auto;
  .title {
    margin-top: 62px;
    width: 1053.57px;;
    margin-left: auto;
    margin-right: auto;
    font-family: Poppins-Bold;
    font-size: 36px;
    // font-weight: bold;
    line-height: 1.17;
    text-align: center;
    color: #062440;
  }
  .contents {
    margin-top: 62px;
    width: 1210px;
    min-height: 701px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    .contents_item {
      min-height: 700px;
      width: 295px;
      border: 1px solid #e6ebf2;
      padding: 20px 0 20px 20px;
      box-sizing: border-box;
      border-radius: 5px;
      text-align: left;
      .character{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .contents_item_name {
        font-family: Poppins-Bold;
        font-size: 20px;
        // font-weight: bold;
        line-height: 1.3;
        color: #314455;
      }
      .contents_item_object{
        width: fit-content;
        height: 20px;
        font-family: 'Poppins-bold';
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #062440;
        padding: 10px 12px;
        background: #F7F8FA;
        border-radius: 5px;
        margin-right: 10px;
      }
      .IDcontents_item_object{
        font-size: 12.8px;
      }
      .contents_paid_annually{
        // margin-top: 20px;
        font-family: Poppins;
        font-size: 14px;
      }
      .contents_paid_annually_empty{
        opacity: 0;
        font-family: Poppins;
        font-size: 14px;
      }
      .contents_item_price {
        margin-top: 20px;
        font-family: Poppins;
        font-size: 14px;
        .item_price {
          font-size: 36px;
          // font-weight: 900;
          font-family: Poppins-Bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: normal;
          text-align: left;
          color: #062440;
        }
      }
      .seats_number {
        margin-top: 40px;
        font-family: Poppins;
        font-size: 14px;
        line-height: 1.43;
        color: #062440;
        padding-bottom: 10px;
        margin-right: 20px;
        border-bottom: 1px solid #e6ebf2;
      }
      .book_a_demo {
        width: 143px;
        height: 42px;
        display: inline-block;
        border: 1px solid #1890ff;
        border-radius: 5px;
        font-family: Poppins-Bold;
        font-size: 16px;
        // font-weight: bold;
        line-height: 1.38;
        text-align: center;
        line-height: 42px;
        color: #1890ff;
        margin-top: 20px;
        transition: all 0.2s ease;
        &:hover {
          cursor: pointer;
          background-color: #1890ff;
          color: #fff;
        }
        &:active{
          background-color: #0757ae;
        }
      }
      .IDbook_a_demo{
        width: 180px;
      }
      .plan_includes {
        margin-top: 20px;
        padding-bottom: 10px;
        font-family: Poppins-Bold;
        font-size: 14px;
        // font-weight: 600;
        line-height: 1.43;
        color: #062440;
      }
      .plan_include_service {
        font-family: Poppins-Bold;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.43;
        color: #062440;
      }
      .plan_details {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        p {
		      max-width: 220px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: left;
          color: #314455;
        }
        span.ismore {
          margin-left: 10px;
          width: 16px;
          height: 16px;
          img {
            // width: 100%;
            height: 100%;
			margin-left: -5px;
          }
          position: relative;
          .info_popover {
            position: absolute;
            box-shadow: 0 2px 6px 0 rgba(164, 164, 164, 0.7);
            align-items: center;
            background-color: #062440;
            border-radius: 5px;
            // padding-top: 20px;
            z-index: 99;
            width: 297px;
            // min-height: 50px;
            padding: 20px 25px 20px 25px;            
            box-sizing: border-box;
            // display: flex;   
                display: flex;
    align-items: center;/*垂直居中*/
    // height:300px;
         
            div {  
              font-family: Poppins;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: -0.17px;
              text-align: left;
              color: #fff;
              // margin-top: 5px;
            }
          }
          .info_popover_to_left {
            left: -297px;
          }
        }
      }
    }
    .contents_item_hot {
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1) !important;
      border: solid 1px #1890ff !important;
      background-color: #eef7ff !important;
      position: relative;
    }
    // .hot_btn {
    //   background-color: #1890ff !important;
    //   color: #fff !important;
    // }
    .most_popular_badge {
      position: absolute;
      bottom: -18px;
      right: 82px;
      width: 131px;
      height: 38px;
      background: url("../../assets/imgs/pc/feature/feature_most_popular.png")
      no-repeat center center;
      background-size: 131px 38px;
    }
  }
  .compare_contents {
    width: 1210px;
    // height: 5000px;
    margin-top: 65px;
    // border: 1px solid red;
    table,
    table tr th,
    table tr td {
      border: 1px solid #e6ebf2;
      box-sizing: border-box;
      font-family: Poppins;
      font-size: 16px;
      color: #062440;
    }
    th {
      height: 50px;
    }

    .compare_table {
      text-align: center;
      border-collapse: collapse;
      box-sizing: border-box;
      .compare_subtile {
        background-color: #eef7ff;
        .item_name {
          text-align: left;
          span {
            font-family: Poppins-Bold;
            font-size: 20px;
            // font-weight: bold;
            color: #062440;
            padding-left: 20px;
          }
        }
      }
      .no_border {
        border-top: 1px solid rgba(0, 0, 0, 0);
        border-left: 1px solid rgba(0, 0, 0, 0);
      }
      .item_name {
        width: 500px;
        // 410px before
        text-align: left;
        span {
          padding-left: 20px;
          font-family: Poppins;
          font-size: 16px;
          font-weight: normal;
          color: #062440;
        }
      }
      // wwjw
      .item_content {
        width: 710px;
        // 800px before
        display: flex;
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0);
        border-top: 1px solid #e6ebf2;
        div {
          width: 177px;
          // 200px before
          span.item_content_text {
            font-family: Poppins;
            font-size: 16px;
            font-weight: normal;
            color: #314455;
          }
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
      .item_content_type1 {
        justify-content: center;
      }
      .item_content_type2 {
        justify-content: space-between;
      }
      .item_content_type3 {
        justify-content: flex-end;
      }
    }
  }
  .back_to_top {
    width: 119px;
    height: 42px;
    border: 1px solid #1890ff;
    margin-top: 84px;
    margin-left: auto;
    margin-right: auto;
    font-family: Poppins;
    font-size: 16px;
    text-align: center;
    color: #1890ff;
    line-height: 42px;
    border-radius: 5px;
    &:hover {
      cursor: pointer;
      background-color: #096dd9;
      color:#fff;
    }
    &:active{
      background-color: #0757ae;
    }
  }
  .IDback_to_top{
    width:160px;
  }
  .trusted{
    height: 200px;
    margin: 80px auto;
  }
  .trusted_by {
    margin-top: 146px;
    .trusted_by_title {
        font-family: Poppins-Bold;
        font-size: 36px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: center;
        color: #062440;
    }
    .trusted_by_icon_content {
      margin: 90px auto 170px;
      width: 1115px;
      .carou_item_clients {
        height: 225px;
        display: flex;
        flex-wrap: wrap;
        .carou_item_client_logo {
          width: 175px;
          height: 70px;
          margin-right: 45px;
          margin-bottom: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          img.width_rely_img {
            width: 100%;
          }
          .height_rely_img {
            height: 100%;
          }
        }
      }
    }
  }
}

/deep/.title-size{
  // height: 400px !important;
  .title{
    width: fit-content !important;
    height: 42px !important;
    font-family: 'Poppins-Bold'!important;
    font-size: 36px !important;
    line-height: 42px !important; 
    white-space: nowrap !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    color: #062440 !important;
    margin-bottom: 30px !important;
  }
}


@media only screen and (max-width: 1024px) {
  .contents{
    .contents_item {
      min-height: 750px !important;
    }
  }
}

.pricingTabsstyle{
  position: relative;
  margin-top: 40.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // min-width:1260px;
}
.yearLogo{
  position:absolute;
  top: -38px;
  right: 408px;
  font-family: 'Poppins-Bold';
  font-size: 14px;
  line-height: 20px;
  color: #12B571;
  z-index: 2;
  pointer-events: none;
}
.el-tabs--border-card{
  .el-tabs__content{
  }
  .el-tabs__header {
    width:408px;

  }
}
</style>



<style lang="scss">

.el-tabs--border-card{
  border: none;
  box-shadow:none;
  .el-tabs__content{
    overflow:unset;
  }
  .el-tabs__header {
    color: #67788C;
    height: 56px;
    // width:408px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom:none;
    background-color: #F7F8FA;
    border-radius: 4px;
    gap: 12px;
    align-self: center;
    #tab-second{
      display:flex;
      justify-content: flex-start;
    }

    .el-tabs__item{
      color: #67788C;
      // min-width: 200px;
      // max-width: 205px;
      font-family: 'Poppins-bold';
      line-height: 24px;
      border-radius: 4px;
      height: 20px;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      border: none;
      padding:10px 12px;
      width:200px;
      &:not(.is-disabled):hover{
        color: #67788C;
      }
    }
  }
}
.el-tabs__nav-wrap .is-top{
  background: rgba(247, 248, 250, 0.6);
  padding:10px 12px;
  border-radius:4px;
}
.el-tabs__nav-scroll{
}
.el-tabs__nav{
  gap: 12px;
}
.el-tabs__nav .is-top{
  display:flex;
  justify-content: center;
  align-items:center;
  min-height: 36px;
  min-width: 155px;
  max-width: 200px;
  // width:408px;
}
.is-top{
  display:flex;
  justify-content:center;
  align-items:center;
  margin:0;
}
.is-active{
  display:flex;
  justify-content:center;
  align-items:center;
  background-color: #ffffff !important;
  color:#062440 !important;
  // margin:0 12px;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active{
  border:none;
}
// .el-tabs--border-card{
//   // color:#67788C;
// }

</style>
